<template>
  <div>
    <page-header-admin
      class="mb-2"
      :title="$t('Rejected mentorship requests')"
    />
    <b-card no-body>
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Records') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                type="search"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        :items="mentorshipRequests"
        responsive
        :fields="fields"
        :filter="searchQuery"
        :filter-function="filterFunction"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="''"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-provider-sorting
        no-provider-paging
        no-provider-filtering
        no-sort-reset
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #head()="{label}">
          {{ label | upper }}
        </template>

        <!-- eslint-disable-next-line -->
        <template #cell(mentor.user.surname)="{item}">
          <b-link :to="{ name: 'admin-mentor-page', params: { id: item.mentor.id } }">
            {{ getMentorVisibleFullName(item.mentor) }}
          </b-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template #cell(mentee.user.surname)="{item}">
          <b-link :to="{ name: 'admin-mentee-page', params: { id: item.mentee.id } }">
            {{ item.mentee.user.name }} {{ item.mentee.user.surname }}
          </b-link>
        </template>

        <template #cell(package_name)="{item}">
          {{ $t(item.package_name) }} <span v-if="item.scholarship">({{ item.scholarship.name }})</span>
        </template>

        <template #cell(created_at)="{item}">
          {{ item.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }}
        </template>

        <template #cell(reject_reason)="{item}">
          <div
            class="reject-reason text-primary cursor-pointer"
            @click="showFullMessage({ text: item.reject_reason })"
          >
            {{ item.reject_reason }}
          </div>
        </template>

      </b-table>
    </b-card>
    <div>
      <b-row>
        <b-col
          class="d-flex justify-content-end"
        >
          <b-pagination
            v-model="currentPage"
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            :per-page="perPage"
            first-number
            last-number
            :total-rows="totalRows"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <message-modal ref="message-modal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import MessageModal from '@mentoring-platform/views/components/blocks/MessageModal.vue'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import { useMentorshipRequest, useMentorHelpers } from '@mentoring-platform/composables'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BRow,
    BTable,
    MessageModal,
    PageHeaderAdmin,
    vSelect,
  },
  filters: {
    formatGreekDate,
    upper,
  },
  data() {
    return {
      perPage: 50,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      searchQuery: null,
      sortBy: 'created_at',
      isSortDirDesc: true,
    }
  },
  computed: {
    fields() {
      return [{
        key: 'mentor.user.surname',
        label: this.$t('Mentor'),
        sortable: true,
      }, {
        key: 'mentee.user.surname',
        label: this.$t('Mentee'),
        sortable: true,
      }, {
        key: 'package_name',
        label: this.$t('Package'),
        sortable: true,
      }, {
        key: 'created_at',
        label: this.$t('Request date'),
        sortable: true,
      }, {
        key: 'reject_reason',
        label: this.$t('Reject reason'),
      }]
    },
    totalRows() {
      return this.mentorshipRequests.length
    },
  },
  methods: {
    showFullMessage(message) {
      this.$refs['message-modal'].show(message)
    },
    filterFunction(item, filterProp) {
      const regex = new RegExp(filterProp.trim(), 'gi')
      return this.$t(item.package_name).match(regex)
        || `${item.mentee.user.name} ${item.mentee.user.surname}`.match(regex)
        || item.mentee.user.email.match(regex)
        || this.getMentorVisibleFullName(item.mentor).match(regex)
        || item.mentor.user.email.match(regex)
        || (item.scholarship && item.scholarship.name.match(regex))
    }
  },
  setup() {
    const { getMentorVisibleFullName } = useMentorHelpers()
    const { mentorshipRequests, getRejectedMentorshipRequests } = useMentorshipRequest()
    getRejectedMentorshipRequests()

    return {
      getMentorVisibleFullName,
      mentorshipRequests,
    }
  },
}
</script>

<style lang="scss" scoped>
  .reject-reason {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
