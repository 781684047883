<template>
  <b-modal
    id="message-modal"
    ref="message-modal"
    centered
    size="lg"
    :title="$t('Message')"
    title-class="font-weight-bolder"
    hide-footer
  >
    <b-form-textarea
      v-model="text"
      class="mt-2 mb-4"
      rows="5"
      no-resize
    />
  </b-modal>
</template>

<script>
import {
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BModal,
  },
  data() {
    return {
      text: null,
    }
  },
  methods: {
    show(message) {
      this.text = message.text
      this.$refs['message-modal'].show()
    },
    confirmRejection() {
      this.$refs['message-modal'].hide()
    },
  },
}
</script>

<style scoped>

</style>
