var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header-admin',{staticClass:"mb-2",attrs:{"title":_vm.$t('Rejected mentorship requests')}}),_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('Records')))]),_c('v-select',{staticClass:"per-page-selector d-inline-block ml-50 mr-1",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"type":"search","placeholder":_vm.$t('Search...')},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)])],1)],1),_c('b-table',{staticClass:"position-relative",attrs:{"items":_vm.mentorshipRequests,"responsive":"","fields":_vm.fields,"filter":_vm.searchQuery,"filter-function":_vm.filterFunction,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":'',"sort-desc":_vm.isSortDirDesc,"no-provider-sorting":"","no-provider-paging":"","no-provider-filtering":"","no-sort-reset":"","per-page":_vm.perPage,"current-page":_vm.currentPage},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head()",fn:function(ref){
var label = ref.label;
return [_vm._v(" "+_vm._s(_vm._f("upper")(label))+" ")]}},{key:"cell(mentor.user.surname)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"to":{ name: 'admin-mentor-page', params: { id: item.mentor.id } }}},[_vm._v(" "+_vm._s(_vm.getMentorVisibleFullName(item.mentor))+" ")])]}},{key:"cell(mentee.user.surname)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"to":{ name: 'admin-mentee-page', params: { id: item.mentee.id } }}},[_vm._v(" "+_vm._s(item.mentee.user.name)+" "+_vm._s(item.mentee.user.surname)+" ")])]}},{key:"cell(package_name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.package_name))+" "),(item.scholarship)?_c('span',[_vm._v("("+_vm._s(item.scholarship.name)+")")]):_vm._e()]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatGreekDate")(item.created_at,{ month: '2-digit', year: 'numeric', day: '2-digit' }))+" ")]}},{key:"cell(reject_reason)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"reject-reason text-primary cursor-pointer",on:{"click":function($event){return _vm.showFullMessage({ text: item.reject_reason })}}},[_vm._v(" "+_vm._s(item.reject_reason)+" ")])]}}])})],1),_c('div',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"prev-class":"prev-item","next-class":"next-item","per-page":_vm.perPage,"first-number":"","last-number":"","total-rows":_vm.totalRows},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('message-modal',{ref:"message-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }